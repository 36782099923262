// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  demo: {
    title: `한국어`,
    introduction: `로렘 입숨은 인쇄와 조판 산업의 단순한 가짜 텍스트이다. 로렘 입숨은 1500년대 이후로 업계의 표준 더미 텍스트였다. 그 때 무명의 프린터가 활자의 갤리선을 가져와서 그것을 스크램블하여 활자 견본 책을 만들었다. 그것은 5세기 동안 살아남았을 뿐만 아니라 전자 조판으로 도약했으며, 본질적으로 변하지 않았다. 1960년대에 로렘 입숨의 구절이 포함된 레트라셋 시트가 출시되면서 대중화되었으며, 최근에는 로렘 입숨 버전을 포함한 알두스 페이지메이커와 같은 데스크톱 출판 소프트웨어가 출시되었다.`,
  },
  docs: {
    hi: `안녕하세요`,
    description: `도와드릴까요?`,
    documentation: `문서`,
  },
  app: `app`,
  user: `user`,
  list: `list`,
  edit: `edit`,
  shop: `shop`,
  post: `post`,
  mail: `mail`,
  chat: `chat`,
  cards: `cards`,
  create: `create`,
  general: `general`,
  profile: `profile`,
  account: `account`,
  product: `product`,
  invoice: `invoice`,
  details: `details`,
  checkout: `checkout`,
  calendar: `calendar`,
  analytics: `analytics`,
  description: `description`,
};

export default en;
